//==============================================================================
// List of Links
//
// List of links that detects the current active page
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';

import { IListOfLinksProps, ILinksData } from './list-of-links.props.autogenerated';

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'list-of-links';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ListOfLinks component
 * @extends {React.Component<IListOfLinksProps<{}>>}
 */
//==============================================================================
class ListOfLinks extends React.Component<IListOfLinksProps<{}>> {
    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { config } = this.props;
        const hasLinks = !!config.links?.length;

        return (
            <div className={classnames(BASE_CLASS, config.className)}>
                {hasLinks && this._renderContent(config.links!)}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render content
    //------------------------------------------------------
    private readonly _renderContent = (links: ILinksData[]): JSX.Element => {
        const { context } = this.props;
        const currentPage = context.request.url.requestUrl.pathname;
        const signedIn = context.request.user.isAuthenticated;

        return (
            <div className={cls('links')}>
                {links.map((cta: ILinksData, index: number) => {
                    const isActive = cta.linkUrl?.destinationUrl?.split('?')[0] === currentPage;

                    // If link requires sign-in and user is not signed in, do not render link
                    if (cta.signedIn && !signedIn) {
                        return null;
                    }

                    return (
                        <a
                            key={index}
                            className={classnames(cls('link'), cta.className, { active: isActive })}
                            title={cta.linkText}
                            href={cta.linkUrl?.destinationUrl}
                            aria-label={cta.ariaLabel}
                            target={cta.openInNewTab ? '_blank' : undefined}
                            rel='noreferrer'
                            role='button'
                        >
                            {cta.linkText}
                        </a>
                    );
                })}
            </div>
        );
    };
}

export default ListOfLinks;
